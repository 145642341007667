.App-header img {
    max-width: 700px; /* or any size you prefer */
    /* additional styling as needed */
  }
  

body {
    font-family: -apple-system, Segoe UI, Ubuntu, Helvetica, Arial;
    background-color: #ffffff;
    color: #353739;
    margin: 0;
    overflow-y: scroll;
  }
  
  .App {
    position: relative;
    width: 70%;
    margin: 2em auto;
  }
  
  .App-header {
    text-align: center;
  }
  
  input {
    font-size: 1.1em;
    height: 2.8em;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    padding-left: .75em;
    width: 80%;
    outline: none;
  }
  
  button.right {
    color: white;
    background-color: #0092e5;
    border: none;
    border-radius: 0 5px 5px 0;
    height: 3.03em;
    font-size: 1.1em;
    font-weight: 600;
    outline: none;
  }

  button.left {
    color: white;
    background-color: #0092e5;
    border: none;
    border-radius: 5px 0 0 5px;
    height: 3.03em;
    font-size: 1.1em;
    font-weight: 600;
    outline: none;
  }
  
  button:hover {
    background-color: #2779bd;
  }
  
  /* Additional styles for progress bar, table, and other elements */
  